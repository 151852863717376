<script>
  import { links } from "svelte-routing";
  import ScrollUp from "./ScrollUp.svelte";
</script>

<ScrollUp />

<header>
  <h1>Some extra info</h1>
</header>

<div use:links>
  <h3>Ceremony info?</h3>
  <ul>
    <li>Watch us say nice things to each other</li>
    <li>
      3:45pm, Saturday 9 July 2022 (for 4.00pm start - it'll be over quick so no
      dawdling)
    </li>
    <li>
      <a href="https://goo.gl/maps/HjsGpdkNzinBK6KRA">
        Council Chambers, Islington Town Hall, Upper St, Islington, London N1
        2UD
      </a>
    </li>
    <li>In-between Angel and Highbury & Islington Station</li>
  </ul>

  <iframe
    title="How to find Islington Town Hall"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9925.789651814956!2d-0.1021719!3d51.5416944!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x454d94e464594ce9!2sIslington%20Town%20Hall!5e0!3m2!1sen!2sau!4v1578817913410!5m2!1sen!2sau"
    width="500"
    height="200"
    frameborder="0"
    style="border:0;"
    allowfullscreen=""
  />

  <h3>Drinking and eating info?</h3>

  <ul>
    <li>We'll feed you and buy you drinks</li>
    <li>
      From 4.45pm-ish to 11pm at
      <a href="http://www.thedrapersarms.com/">The Drapers Arms</a>
    </li>
    <li>
      <a href="https://goo.gl/maps/k8wThWuktrnfetuJA">
        44 Barnsbury St, Islington, London N1 1ER
      </a>
    </li>
    <li>A 6 min walk from Town Hall</li>
  </ul>

  <iframe
    title="How to find The Drapers Arms"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9926.055401202473!2d-0.1084581!3d51.540476!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15d6bc0dbe82cb96!2sThe%20Drapers%20Arms!5e0!3m2!1sen!2sau!4v1578818093238!5m2!1sen!2sau"
    width="500"
    height="200"
    frameborder="0"
    style="border:0;"
    allowfullscreen=""
  />

  <h3>Dress code?</h3>
  <p>
    Whatever you feel like wearing to a town hall and gastropub. For serious.
    It's super low-key.
  </p>
  <p>Phil may be wearing converse (it's an ongoing discussion).</p>

  <h3>Gifts?</h3>

  <p>No thanks. Seeing your faces is enough.</p>

  <h3>Kids?</h3>

  <p>Not in Kelly's womb. Jokes.</p>

  <p>
    Please let us know if you'd like any little ones to join as we do now have
    space for a few.
  </p>

  <h3>
    What's this wedding stuff? Weren't you getting a Civil Partnership because
    of the Patriarchy or something?
  </h3>

  <p>
    Slight change of plan thanks to Covid. To make having this party as likely
    as possible, the legal bit needs to be done in Australia, who don't have
    civil partnerships. Getting married just simplifies things for throwing the
    celebration with you all in London.
  </p>
  <p>
    Having read the small print we also now know marriage will make sure we get
    all the legal protections in all the countries.
  </p>

  <h3>So, it's not a real wedding then?</h3>

  <p>
    Oi. Keep that up and we'll turn it into a cash bar. While we won't be
    getting legally wed in the London ceremony, we think of it as the real
    wedding as we'll be standing up in front of you all to say nice things to
    each other.
  </p>

  <h3>Will there be cake?</h3>

  <p>There'll be dessert. No word yet if cake is an option.</p>

  <h3>Seriously, what's the dress code?</h3>

  <p>
    Neat casual if you insist. Jeans are cool. Maybe pick a top with a collar
    over a holey t shirt. No need to bother with heels as Kelly likely won't.
  </p>

  <h3>
    Any more questions, comments or recommendations for songs to add to the
    playlist?
  </h3>

  <p>
    Yes please. Text, WhatsApp, Facebook or email us at
    <a href="mailto:kellyadunlop@gmail.com">kellyadunlop@gmail.com</a>
    and
    <a href="mailto:philnash@gmail.com">philnash@gmail.com</a>
    .
  </p>
</div>

<style>
  iframe {
    width: 100%;
    max-width: 100%;
  }

  h3 {
    margin-top: 2em;
  }

  ul {
    padding-left: 1em;
  }
</style>
