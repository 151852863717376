<script>
  import { Link } from "svelte-routing";
</script>

<nav>
  <Link to="/">Home</Link>
  <Link to="faq">Some extra info</Link>
</nav>

<style>
  nav {
    margin-top: 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 2em;
    text-align: center;
  }
  :global(nav a) {
    display: block;
    color: #fff;
    background: #407d76;
    border: 1px solid #407d76;
    text-decoration: none;
    padding: 0.5em;
    font-size: 20px;
    font-family: "Josefin Sans", "Helvetica Neue", Arial, sans-serif;
    flex-grow: 1;
    margin-bottom: 10px;
    border-radius: 3px;
    transition: all 0.25s;
  }
  :global(nav a:hover),
  :global(nav a[aria-current="page"]) {
    background: #fff;
    border-color: #264d48;
    color: #264d48;
  }

  @media (min-width: 590px) {
    :global(nav a) {
      display: inline-block;
      margin-right: 10px;
    }
    :global(nav a:last-child) {
      margin-right: 0;
    }
  }
</style>
